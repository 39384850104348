<template>
    <div id="customer-order" v-if="order && order != -1">
        <router-link class="text-danger" to="/customer/dashboard">
            <i class="fas fa-angle-left mr-2"></i>
            Return to Account Details
        </router-link>
        <div class="row mt-4">
            <div class="col-md-9">
                <!-- <div class="order-title">
                    <h5>Order #{{order.name}}</h5>
                    <span><router-link :to="getResponseURL()"><i class="fas fa-external-link-alt"></i></router-link></span>
                </div> -->
                <div>
                    <span>{{ getUpdatedOrder() }}</span>
                </div>
                <h5 class="mt-4">Order HISTORY</h5>
                <div class="mt-4">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="40%">Product</th>
                                <th>SKU</th>
                                <th>Price</th>
                                <th class="text-right">Quantity</th>
                                <th class="text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in order.line_item" :key="item.id">
                                <td>{{ item.title }}</td>
                                <td></td>
                                <td>RM{{ (item.price).toFixed(2) }}</td>
                                <td class="text-right">{{ item.quantity }}</td>
                                <td class="text-right">RM{{ (item.total_price).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td colspan="4">Subtotal</td>
                                <td class="text-right">RM{{ (order.subtotal_price).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td colspan="4">Discount</td>
                                <td class="text-right">RM{{ (order.total_discounts).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td colspan="4">Shipping</td>
                                <td class="text-right">RM{{ (order.total_shipping).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td colspan="4">Taxes</td>
                                <td class="text-right">RM{{ (order.total_tax).toFixed(2) }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td class="border-bottom font-weight-bold" colspan="4">Total</td>
                                <td class="border-bottom font-weight-bold text-right">RM{{ order.total_price }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="col-md-3">
                <h5>References</h5>
                <p>
                    <span><b>Payment Status : </b>{{getPaymentStatus()}}</span>
                </p>
                <p>
                    <span><b>Fulfillment Status : </b>{{getFulfillmentStatus()}}</span>
                </p>
                <p>
                    <span><b>Delivery Method : </b> <br /> {{order.delivery_type.name}}</span> <br />
                </p>
                <p>
                    <span><b>Delivery Address : </b> <br /> {{ order.delivery_address.firstname }} {{ order.delivery_address.lastname }}</span> <br />
                    <span>{{ order.delivery_address.address1 }}, {{ order.delivery_address.address2 }}, {{ order.delivery_address.city }}, {{ order.delivery_address.province }}</span>
                </p>

                <p v-if="order.tracking_number">
                    <span>
                        <b>Tracking Number : </b> <br /> 
                        {{ getTrackingLabel(order.courier_type) }} <i>(<a :href="getTrackingURl(order.courier_type, order.tracking_number)" target="_blank" class="text-primary">{{ order.tracking_number }}</a>)</i> <br />
                    </span>
                    <!-- <a :href="order.order_status_url" target="_blank"><i>Click here for payment status details.</i></a> -->
                </p>
            </div>
        </div>
    </div>
    <div v-else-if="order == -1">
        <h6>Sorry, there was an error with this order.</h6>
    </div>
</template>

<script>
import API from './../../utils/API'
import moment from 'moment'

export default {
    data(){
        return {
            order: null
        }
    },
    methods: {
        getUpdatedOrder(){
            // return this.order.updatedAt
            // return moment(this.order.updatedAt, 'YYYYMMDD hmms').format('LLL')
            return moment(this.order.updatedAt, 'YYYYMMDD').format('LL')
        },
        getPaymentStatus(){
            switch (this.order.financial_status) {
                case 'pending':
                    return 'Pending';
                case 'not_paid':
                    return 'Not Paid';
                case 'paid':
                    return 'Paid';
            
                default:
                    'Pending';
            }
        },
        getFulfillmentStatus(){
            switch (this.order.fulfillment_status) {
                case 'pending':
                    return 'Pending';
                case 'unfulfilled':
                    return 'In Progress';
                case 'fulfilled':
                    return 'Delivered';
            
                default:
                    'Pending';
            }
        },
        getResponseURL(){
            let resURL = '/checkout/response?payment='
            if(this.order.financial_status == 'paid') resURL += `success&order=${this.order.id}`
            else resURL += `failed&order=${this.order.id}`
            return resURL
        },
        getTrackingLabel(_name){
            switch (_name) {
                case 'poslaju':
                    return 'Poslaju'
                    break;
                case 'jnt':
                    return 'J&T Express'
                    break;
                case 'skynet':
                    return 'Skynet Express'
                    break;
                case 'dhl':
                    return 'DHL eCommerce'
                    break;
                case 'fedex':
                    return 'FedEx'
                    break;
                case 'ups':
                    return 'UPS'
                    break;
                case 'ninja':
                    return 'Ninja Van'
                    break;
            
                default:
                    break;
            }
        },
        getTrackingURl(_name, _id){
            switch (_name) {
                case 'poslaju':
                    return `https://www.tracking.my/poslaju/${_id}`
                    break;
                case 'jnt':
                    return `https://www.tracking.my/jt/${_id}`
                    break;
                case 'skynet':
                    return `https://www.tracking.my/skynet/${_id}`
                    break;
                case 'dhl':
                    return `https://www.tracking.my/dhl-ecommerce/${_id}`
                    break;
                case 'fedex':
                    return `https://www.tracking.my/fedex/${_id}`
                    break;
                case 'ups':
                    return `https://www.tracking.my/ups/${_id}`
                    break;
                case 'ninja':
                    return `https://www.ninjavan.co/en-my/tracking?id=${_id}`
                    break;
            
                default:
                    break;
            }
        }
    },
    async mounted(){
        API.get(`/orders/${this.$route.params.id}`)
            .then(resOrder => this.order = resOrder.data)
            .catch(err => {
                this.order = -1
            })
    }
}
</script>

<style scoped>
.table th {
    color: #7CC6D6;
    font-weight: 500;
    border-top: none;
    border-bottom: none;
}

.table td {
    font-weight: 300;
    padding: 0.75rem 1rem;
}

#customer-order {
    /* font-family: "Karla",arial,sans-serif; */
    font-size: 14px;
    line-height: 30px;
    color: #333;
}
#customer-order h1, h2, h3, h4, h5 {
    line-height: 1.4;
    margin: 0 0 20px;
    /*font-family: "Roboto Slab",serif;*/
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
}
#customer-order a {
    color: #333;
}
#customer-order a.text-primary {
    color: #a91a18!important;
}
#customer-order a.text-primary:hover {
    color: #cc2c2a!important;
    text-decoration: underline;
}
#customer-order a:hover {
    color: #000;
    text-decoration: unset;
}

.order-title h5 {
    display: inline-block;
    margin-right: 10px;
}
.order-title span {
    font-size: 10px;
}
</style>